<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP JOB SITE VIEW

type    : view

uses    : inventory-map

route   : /jobSites/view/:uuid
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <v-container class="ma-3 app-job-site-view">
    <div class="display-1 text-xs-left">View Job Site</div>
    <div style="text-align: left; color: lightgray">
      ID : {{ jobSite.uuid }}
    </div>
    <v-layout row>
      <v-flex xs12 mt3>
        {{ jobSite.name }}
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12 mt3>
        {{ jobSite.address }}
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        {{ jobSite.city }}, {{ jobSite.state }} {{ jobSite.zip_code }}
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        {{ jobSite.country }}
      </v-flex>
    </v-layout>
    <v-layout row class="mr-3">
      <v-flex>
        <inventory-map
          ref="inventoryMap"
          v-bind:jobsites="[jobSite]"
          v-bind:tenantSettings="tenantSettings"
          :can_add_trees="true"
          v-bind:client="client"
          inventory_type="trees" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import InventoryMap from '@/components/inventory-map';

  import Tenants from '@/services/Tenants.service.js';

  export default {
    name: 'AppJobSiteView',
    components: {
      'inventory-map': InventoryMap,
    },
    props: {
      uuid: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        jobSite: {},
        client: undefined,
        tenantSettings: {},
      };
    },
    async mounted() {
      // get tenant settings
      // (in an authenticated situation we already have this info
      // via tenantProfile that gets loaded in $auth during the
      // begging of the user's session. This special case
      // is needed for the connected components that need this info
      // passed in for unsecured estimate proposal links)
      this.tenantSettings = this.$auth.tenantProfile
        ? this.$auth.tenantProfile
        : await Tenants.getSettingsInfo(
            this.$auth.userProfile.tenant_uuid
            // unsecured route
          );

      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();
      axios({
        method: 'GET',
        url: '/service_clients/job_sites/' + this.uuid,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }).then(
        (results) => {
          this.jobSite = results.data;
          // console.log( "calling this" )
          // console.log( '/service_clients/clients/' + this.jobSite.client_uuid )

          this.$nextTick(function () {
            axios
              .get('/service_clients/clients/' + this.jobSite.client_uuid, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              })
              .then((clientResponse) => {
                // console.log( "the client response is this" )
                // console.log( clientResponse )
                if (clientResponse) {
                  this.client = clientResponse.data;
                }
              })
              .catch((error) => {
                if (error.hasOwnProperty('response')) {
                  if (error.response.hasOwnProperty('status')) {
                    //use the http status to set a custom error message (with internationalization!)
                    this.errorMessage = 'API call failed';
                  }
                }
              });
          });
        },
        (e) => {
          throw Error('Something went wrong', e);
        }
      );
    },
    updated() {
      // console.log( "dom updated" )

      if (
        this.$refs.inventoryMap &&
        this.$refs.inventoryMap.address &&
        this.$refs.inventoryMap.city &&
        this.$refs.inventoryMap.state &&
        this.$refs.inventoryMap.uuid &&
        this.$refs.inventoryMap.client
      ) {
        // console.log( "dom updated, calling map toggle" )
        this.$refs.inventoryMap.renderTreesMap();
      }
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
